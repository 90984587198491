@use '../utils/breakpoints' as *;

.advice {
  padding: 72px 0;
  background-color: var(--hard-blue);
  position: relative;
  overflow: hidden;

  &-img-right {
    position: absolute;
    bottom: 15px;
    right: 0;
    display: none;
    @include breakpoint(small up) {
      display: block;
    }
  }

  &-img-left {
    position: absolute;
    bottom: 50px;
    left: 4%;
    display: none;
    @include breakpoint(small up) {
      display: block;
    }
  }

  &-ttl {
    color: var(--white);
    text-align: center;
    margin-bottom: 35px;
    position: relative;
    padding: 0;
    font-size: 22px;
    @include breakpoint(small up) {
      font-size: 36px;
      padding: 0 80px;
    }
    img {
      position: absolute;
      top: -40px;
      right: -30px;
      display: none;
      @include breakpoint(small up) {
        display: block;
      }
    }
  }

  &-text {
    text-align: center;
    margin-bottom: 32px;
    color: #d2d7e1;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    gap: 15px;
    z-index: 2;
    position: relative;
    @include breakpoint(large up) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
  }

  &-item {
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(96.44deg, #5286ce 2.54%, #11338a 97.36%);
    border-image-slice: 2;

    &-inner {
      padding: 24px;
      background: rgba(5, 15, 40, 0.44);
      backdrop-filter: blur(6px);
      transform: translateZ(0);
    }

    &-top {
      display: flex;
      gap: 28px;
      align-items: center;
      margin-bottom: 24px;
    }

    &-name {
      color: var(--white);
      font-weight: 700;
      font-size: 16px;
      @include breakpoint(small up) {
        font-size: 22px;
      }
    }

    &-content {
      color: var(--light-grey);
      font-weight: 300;
      @include breakpoint(small up) {
        font-weight: 600;
      }
    }

    &-number {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white);
      background: linear-gradient(320.66deg, #f07300 14.75%, #ffb017 84.81%);
      border-radius: 4px 0;
      width: 40px;
      height: 40px;
      font-weight: 700;
      font-size: 26px;
    }
  }
}
