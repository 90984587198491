@use '../utils/breakpoints' as *;

.pay-cards {
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint(medium up) {
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    background: #edf3fa;
    border: 1px solid var(--white);
    box-shadow:
      -4px -2px 21px hsla(0, 0%, 100%, 0.83),
      6px 3px 15px rgba(136, 165, 191, 0.4);
    border-radius: var(--radius-small);
    padding: 24px 16px;
    text-align: center;
  }

  &-ttl {
    color: var(--primary-dark-color);
    font-weight: 700;
    font-size: 16px;
    @include breakpoint(small up) {
      font-size: 26px;
    }
  }

  &-text {
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 300;
    @include breakpoint(small up) {
      font-weight: 600;
    }
  }

  &-btn {
    width: 100%;
    .button {
      font-size: 14px;
      @include breakpoint(small up) {
        font-size: 16px;
      }
    }
  }
}
