@use '../utils/breakpoints' as *;

.cookies {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f5f9ff;
  color: var(--dark-grey);
  max-height: 100vh;
  overflow: auto;
  z-index: 15;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -6px 0 15px rgba(121, 147, 170, 0.23),
    -4px -2px 21px hsla(0, 0%, 100%, 0.83),
    6px 3px 15px rgba(136, 165, 191, 0.23);

  &-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px;
  }

  &-cont {
    max-width: 1094px;
    max-height: 60px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background: #bbbdc0;
      width: 3px;
      height: 3px;
      &-thumb {
        background: var(--blue-color);
        border: none;
        border-radius: 0;
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    .button {
      min-width: 130px;
    }
  }

  &-close {
    display: flex;

    svg {
      fill: var(--dark-grey);
      transition: fill var(--speed-fast);
    }

    &:hover {
      svg {
        fill: var(--dark-grey);
      }
    }
  }
}
