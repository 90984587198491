@use '../utils/breakpoints' as *;

table:not([class]) {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  display: -webkit-box;

  &::-webkit-scrollbar {
    background: var(--light-grey);
    width: 5px;
    height: 5px;

    &:horizontal {
      border-radius: 0;
    }

    &-thumb {
      background: var(--primary-dark-color);
      border: 2px solid var(--primary-dark-color);
      border-radius: 0;
    }
  }

  .table-block & {
    margin: 20px 0;
  }

  tbody {
    min-width: 100%;
    display: table;
  }

  tr:first-child td,
  tr:first-child th {
    border-top: 1px solid var(--primary-dark-color);
  }

  tr td,
  tr th {
    border-right: 1px solid var(--primary-dark-color);
    border-left: 1px solid var(--primary-dark-color);
    border-bottom: 1px solid var(--primary-dark-color);
  }

  td {
    background: #edf3fa;
    padding: 17px 20px;
    min-width: 250px;
    font-size: 14px;
    font-weight: 300;
    @include breakpoint(small up) {
      font-weight: 600;
    }
    a {
      text-decoration: none;
      color: var(--dark-grey);
    }
  }
  //
  //  th {
  //    background: var(--primary-color);
  //    color: var(--on-primary-color);
  //    padding: 10px;
  //    font: 600 16px/1.3 var(--title-font-family);
  //
  //    @include breakpoint(large) {
  //      font-size: 22px;
  //    }
  //  }
  //
  //  td {
  //    padding: 12px 10px;
  //    font-size: 14px;
  //    background: var(--white);
  //
  //    @include breakpoint(large) {
  //      font-size: 16px;
  //    }
  //  }
  //
  //  :where(td, th) {
  //    border-top: 1px solid var(--medium-grey);
  //    border-right: 1px solid var(--medium-grey);
  //    text-align: center;
  //    min-width: 120px;
  //
  //    &:first-child {
  //      border-left: 1px solid var(--medium-grey);
  //    }
  //  }
  //
  //  > :where(thead, tbody):first-child tr:first-child,
  //  > tr:first-child {
  //    > :where(td, th) {
  //      &:first-child {
  //        border-radius: var(--radius) 0 0 0;
  //      }
  //
  //      &:last-child {
  //        border-radius: 0 var(--radius) 0 0;
  //      }
  //    }
  //  }
  //
  //  > :where(tbody, tfoot):last-child tr:last-child,
  //  > tr:last-child {
  //    :where(td, th) {
  //      border-bottom: 1px solid var(--medium-grey);
  //
  //      &:first-child {
  //        border-radius: 0 0 0 var(--radius);
  //      }
  //
  //      &:last-child {
  //        border-radius: 0 0 var(--radius) 0;
  //      }
  //    }
  //  }
  //}
  //
  //.resp-table {
  //  position: relative;
  //  overflow: auto;
  //  max-width: 100%;
  //  margin: 1.5rem 0;
  //}
  //
  //.table-lbl-val {
  //  width: 100%;
  //  border-collapse: collapse;
  //  table-layout: fixed;
  //
  //  @include breakpoint(medium) {
  //    table-layout: auto;
  //  }
  //
  //  :where(td, th) {
  //    padding: 10px;
  //    vertical-align: top;
  //
  //    &:first-child {
  //      padding-left: 0;
  //      color: var(--dark-grey);
  //    }
  //
  //    &:last-child {
  //      padding-right: 0;
  //    }
  //  }
  //
  //  tr:not(:last-child) {
  //    :where(th, td) {
  //      border-bottom: 1px solid var(--light-grey);
  //    }
  //  }
  //
  //  &-wrap {
  //    padding: 14px 24px;
  //    background: var(--white);
  //    border-radius: var(--radius);
  //    box-shadow: var(--shadow-2);
  //    margin: 1.5rem 0;
  //  }
}
