@use '../utils/breakpoints' as *;
@use '../utils/no-first-last-margin' as *;

.pros-cons {
  display: grid;
  gap: 24px;

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    gap: 32px;
  }

  &-itm {
    background: var(--white);
    border: 2px solid var(--white);
    border-radius: var(--radius);
    box-shadow: var(--shadow-3);
  }

  &-ttl {
    font: 600 22px/1.3 var(--title-font-family);
    text-align: center;
    padding: 1rem;
    background: var(--green-light);
    border-radius: var(--radius) var(--radius) 0 0;

    &.red {
      background: var(--red-light);
    }
  }

  &-cont {
    padding: 1rem;

    @include breakpoint(large) {
      padding: 1.5rem;
    }

    @include no-first-last-margin;
  }
}
