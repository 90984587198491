@use '../utils/breakpoints' as *;

.casino-id {
  padding: 22px 26px;
  text-align: center;
  border: 2px solid #fff;
  margin: 30px 0;
  background: linear-gradient(180deg, #fff, #eaf1ff);
  box-shadow:
    -4px -2px 21px hsla(0, 0%, 100%, 0.83),
    6px 3px 15px rgba(136, 165, 191, 0.4);
  border-radius: var(--radius-small);

  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    @include breakpoint(small up) {
      display: grid;
      grid-template-columns: 156px auto auto auto 156px;
    }
  }

  &-img {
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      max-height: 100px;
    }
  }

  &-link {
    a {
      text-decoration: none;
      color: var(--dark-grey);
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-lbl {
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 1.4;
    font-weight: 600;
  }

  &-val {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    color: var(--primary-dark-color);
    a {
      text-decoration: none;
      &:hover {
        color: var(--primary-dark-color);
      }
    }
  }

  &-btn {
    .button {
      min-width: 156px;
    }
  }
}
