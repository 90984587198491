@use '../utils/breakpoints' as *;

.game-slider {
  position: relative;
  overflow: hidden;
  @include breakpoint(small up) {
    padding-bottom: 0;
  }

  &-top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 25px 0;
    align-items: center;
    @include breakpoint(small up) {
      display: grid;
      grid-template-columns: 180px auto;
      gap: 24px;
    }
  }

  &-ttl {
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    color: #0f2e7d;
    margin-bottom: 20px;
  }

  &-item {
    width: 340px;
    background: linear-gradient(162.58deg, #223e89, #483389);
    border-radius: 7px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: auto;
    border: 1px solid var(--white);
    text-align: center;
    gap: 20px;
    color: var(--white);

    &-ttl {
      font-weight: 700;
      font-size: 16px;
      @include breakpoint(small up) {
        font-size: 26px;
      }
    }
    &-img {
      width: 180px;
      height: 180px;
      margin: 0 auto;
    }
    &-link {
      margin-top: auto;
    }
    &-text {
      font-weight: 300;
      line-height: 1.5;
      color: #cadbff;
      @include breakpoint(small up) {
        font-weight: 700;
      }
    }
  }
  &-arrs {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-around;

    &-prev,
    &-next {
      width: 50px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
      }
    }
    @include breakpoint(small up) {
      position: static;
      justify-content: flex-start;
      width: auto;
    }
  }

  .swiper {
    padding-bottom: 84px;
    overflow: visible;
    @include breakpoint(small up) {
      padding-bottom: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin: 0;
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }
}
