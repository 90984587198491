@use '../utils/breakpoints' as *;

.fancy-list {
  --icon-size: 18px;
  list-style: none;
  margin: 24px 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 28px;
    font-size: 16px;
    line-height: 1.4rem;

    @include breakpoint(medium) {
      font-size: 18px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:before {
      content: '';
      display: block;
      width: var(--icon-size);
      height: var(--icon-size);
      position: absolute;
      left: 0;
      top: 0.8rem;
      transform: translateY(-50%);
      background: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.5' width='18' height='18' rx='9' fill='%2342C777'/%3E%3Cpath d='M13 6.5L7.5 12L5 9.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
        no-repeat center / contain;
    }
  }

  &.cons {
    li:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23CD0F0F'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-width='2' d='m6 6 6 6M6 12l6-6'/%3E%3C/svg%3E");
    }
  }
}
