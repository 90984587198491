@use '../utils/breakpoints' as *;

.subscribe {
  &-wrap {
    position: relative;
    background: var(--hard-blue);
    color: var(--light-grey);
    padding: 95px 10px 56px;
  }

  .container {
    display: grid;
    gap: 12px;
    background: linear-gradient(181.75deg, #030f2d 3.54%, #003992 98.52%);
    border-radius: var(--radius-small);
    text-align: center;
    padding: 0 12px 24px;
    position: relative;
    margin-top: 20px;
    @include breakpoint(medium) {
      grid-template-columns: 1fr 338px;
      gap: 40px;
      text-align: left;
      padding: 0;
      background: linear-gradient(91.78deg, #013890 1.51%, #030f2d 91.71%);
    }
  }
  &-img {
    position: absolute;
    top: -45px;
    left: 30px;
    width: 60px;
    height: auto;
    display: none;
    svg {
      max-width: 100%;
    }
    @include breakpoint(medium up) {
      display: block;
    }
  }

  &-image {
    @include breakpoint(medium) {
      order: 2;
    }

    svg {
      max-width: 100%;
      transform: translateY(-20px);
    }
  }

  &-content {
    @include breakpoint(medium) {
      order: 1;
      padding: 64px 0 64px 32px;
    }
  }

  &-ttl {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 16px;
    color: var(--white);

    @include breakpoint(small) {
      font-size: 36px;
    }
  }

  &-text {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 38px;
  }

  form {
    display: grid;
    gap: 12px 16px;

    @include breakpoint(medium) {
      grid-template-columns: 1fr auto;
      max-width: 480px;
    }
  }

  input {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: var(--light-grey);
    border: 1px solid var(--light-grey);
    filter: drop-shadow(8px 8px 39px rgba(0, 0, 0, 0.77));
    transform: translateZ(0);
    border-radius: 4px;
    background: 0 0;
    padding: 14px 12px;
    min-width: 300px;
    margin-right: 16px;
    &::placeholder {
      color: var(--light-grey);
    }
  }
}

.wave {
  width: 100%;
  aspect-ratio: 1440/40;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='40' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23030F2D' d='M0 7.34c706.5 54.47 700-32.03 1440 0v1259.97H0V7.34Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h1440v40H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
    no-repeat top center;
  background-size: cover;
  @include breakpoint(large down) {
    transform: translateY(0.5px);
  }
  @include breakpoint(small down) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='320' height='15' viewBox='0 0 320 15' fill='none'%3E%3Cg clip-path='url(%23frames/dark_wave-mobile_101040_clip0_1306_7711)'%3E%3Cpath d='M0 5.18536C152.5 19.6855 185.5 -11.8145 320 5.18552V1914H0V5.18536Z' fill='%23030F2D'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='frames/dark_wave-mobile_101040_clip0_1306_7711'%3E%3Crect width='320' height='15' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    //transform: translateY(0.5px);
  }
}
