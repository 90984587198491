@use '../utils/breakpoints' as *;

.step-list {
  list-style: none;
  margin: 24px 0;
  padding: 0;
  display: grid;
  gap: 12px;
  counter-reset: step-list;
  counter-set: step-list;

  &-itm {
    display: grid;
    grid-template-areas:
      'count title'
      'cont cont';
    grid-template-columns: auto 1fr;
    gap: 5px 12px;
    background: var(--white);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    padding: 12px;
    counter-increment: step-list;
    overflow: hidden;

    @include breakpoint(medium) {
      grid-template-areas:
        'count title'
        'count cont';
      gap: 8px 25px;
      padding: 0;
    }

    &:before {
      content: counter(step-list);
      grid-area: count;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 44px;
      height: 44px;
      background: var(--primary-color);
      color: var(--on-primary-color);
      border-radius: var(--radius);
      padding: 4px;
      box-sizing: border-box;
      font: 500 16px/1 var(--body-font-family);

      @include breakpoint(medium) {
        border-radius: 0;
        min-width: 25px;
        height: 100%;
      }
    }
  }

  &-ttl {
    grid-area: title;
    align-self: center;
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-color);

    @include breakpoint(medium) {
      margin-top: 12px;
    }
  }

  &-cont {
    grid-area: cont;

    @include breakpoint(medium) {
      margin-bottom: 12px;
      font-size: 14px;
    }
  }
}
