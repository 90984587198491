@use '../utils/breakpoints' as *;

.tabs {
  list-style: none;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;

  @include breakpoint(medium) {
    margin: 1.5rem 0;
  }

  &-ttl {
    display: block;
    font-size: 14px;
    padding: 9px 22px;
    background: var(--light-grey);
    color: var(--on-light-grey);
    text-decoration: none;
    border-radius: var(--radius);
    transition: all var(--speed-fast);

    &:hover {
      background: var(--medium-grey);
      color: var(--on-medium-grey);
    }

    &.is-active {
      background: var(--black);
      color: var(--on-black);
    }
  }

  &-panel {
    margin: 1rem;

    @include breakpoint(medium) {
      margin: 1.5rem 0;
    }
  }
}

[data-tabs-itm]:not(.is-active) {
  display: none;
}

.tabs-alt {
  list-style: none;
  margin: 1rem 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;

  @include breakpoint(medium) {
    margin: 1.5rem 0;
  }

  > * {
    flex-shrink: 0;
    border-bottom: 1px solid var(--light-grey);
  }

  &-ttl {
    display: block;
    list-style: none;
    padding: 14px 32px;
    text-decoration: none;
    position: relative;
    font: 500 14px/1.4 var(--title-font-family);
    color: var(--black);

    &:hover {
      color: var(--primary-color);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 2px solid transparent;
      transition: border-color var(--speed-fast);
    }

    &.is-active {
      color: var(--primary-color);

      &:before {
        border-color: var(--primary-color);
      }
    }
  }
}
