.toc {
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .button.orange.hollow.active {
      background: 0 0;
      border-color: #f07300;
      color: #f07300;
    }
  }
}
