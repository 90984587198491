@use '../utils/breakpoints' as *;

.author {
  .wrap {
    //padding-bottom: 30px;
    font-weight: 600;
  }

  .container {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 30px 24px;
    align-items: center;
    background: #edf3fa;
    border: 1px solid #fff;
    box-shadow:
      -4px -2px 21px hsl(0deg 0% 100% / 83%),
      6px 3px 15px rgb(136 165 191 / 40%);
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 30px;

    @include breakpoint(medium up) {
      grid-template-columns: 86px 1fr;
    }
  }

  .photo {
    width: 48px;
    height: 48px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #fff;
    filter: drop-shadow(0 4px 8px rgba(59, 61, 161, 0.15));
    transform: translateZ(0);
    overflow: hidden;
    @include breakpoint(medium up) {
      width: 90px;
      height: 90px;
    }
    @include breakpoint(large) {
      grid-row: span 2;
      order: 1;
    }

    &Img {
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 0 2px #fff;
      filter: drop-shadow(0 4px 8px rgba(59, 61, 161, 0.15));
      transform: translateZ(0);
    }
  }

  .info {
    @include breakpoint(large) {
      order: 3;
    }
  }

  .label {
    font-size: 14px;
    color: #d2d7e1;
  }

  .name {
    font-size: 18px;
    color: #434d61;
  }

  .text {
    font-size: 14px;
    font-weight: 600;
    @include breakpoint(xsmall down) {
      font-weight: 300;
    }
    @include breakpoint(medium down) {
      text-align: center;
      grid-column: span 2;
    }

    @include breakpoint(large) {
      order: 2;
    }
  }
}
