@use '../utils/no-first-last-margin' as *;

blockquote {
  border-radius: var(--radius);
  box-shadow: var(--shadow-2);
  padding: 20px 20px 20px 70px;
  margin: 1rem 0;
  background:
    url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.78411 33.1986C6.80994 31.1018 5.75003 28.75 5.75003 24.9378C5.75003 18.2294 10.4593 12.2168 17.3075 9.24409L19.0191 11.8853C12.627 15.3429 11.3774 19.8298 10.879 22.6588C11.9083 22.126 13.2557 21.9401 14.5763 22.0628C18.0339 22.3828 20.7594 25.2214 20.7594 28.75C20.7594 30.5292 20.0527 32.2355 18.7946 33.4935C17.5366 34.7516 15.8303 35.4583 14.0511 35.4583C11.9945 35.4583 10.028 34.5192 8.78411 33.1986ZM27.9508 33.1986C25.9766 31.1018 24.9167 28.75 24.9167 24.9378C24.9167 18.2294 29.6259 12.2168 36.4742 9.24409L38.1858 11.8853C31.7937 15.3429 30.544 19.8298 30.0457 22.6588C31.0749 22.126 32.4224 21.9401 33.7429 22.0628C37.2006 22.3828 39.9242 25.2214 39.9242 28.75C39.9242 30.5292 39.2174 32.2355 37.9594 33.4935C36.7013 34.7516 34.995 35.4583 33.2159 35.4583C31.1593 35.4583 29.1928 34.5192 27.9489 33.1986H27.9508Z' fill='%23092265'/%3E%3C/svg%3E")
      12px 8px no-repeat,
    var(--white);
  @include no-first-last-margin;
}
