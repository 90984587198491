.top-cas {
  margin: 24px 0;
  box-shadow:
    -6px 0 15px rgba(136, 165, 191, 0.23),
    -4px -2px 21px hsla(0, 0%, 100%, 0.83),
    6px 3px 15px rgba(136, 165, 191, 0.23);
  border-radius: 15px;
  background: #f5f9ff;

  &-ttl {
    background: linear-gradient(162.58deg, #223e89, #483389);
    border-radius: 15px 15px 0 0;
    font-weight: 700;
    font-size: 18px;
    color: var(--white);
    padding: 16px;
    text-align: center;
  }

  &-item {
    display: grid;
    grid-template-columns: 60px auto 81px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    &:nth-child(even) {
      background-color: var(--white);
    }
  }

  &-bonus {
    color: var(--primary-dark-color);
    font-weight: 700;
    text-align: center;
  }

  &-btn {
    width: 81px;
    a.button {
      padding: 9px 10px;
      font-size: 12px;
      line-height: 1.3;
    }
  }
}
