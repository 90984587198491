@use '../utils/breakpoints' as *;

.grid-list {
  background: #edf3fa;
  border: 1px solid var(--white);
  box-shadow:
    -4px -2px 21px hsla(0, 0%, 100%, 0.83),
    6px 3px 15px rgba(136, 165, 191, 0.4);
  border-radius: var(--radius-small);
  padding: 24px 24px 8px;
  margin-bottom: 32px;

  ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;

    @include breakpoint(large up) {
      grid-template-columns: repeat(4, 1fr);
    }

    a {
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: color, background-color, opacity;
      text-decoration: none;
      color: var(--primary-dark-color);
      font-weight: 700;
      &:hover {
        color: #186baa;
      }
    }
  }
}
