@use '../utils/breakpoints' as *;

.accordion {
  list-style: none;
  margin: 1.5rem auto;
  padding: 0;
  display: grid;
  gap: 12px;
  max-width: 100%;
  @include breakpoint(large) {
    max-width: 70%;
  }

  &-item {
    border: 1px solid var(--primary-dark-color);
    border-radius: var(--radius-small);
    padding: 8px 24px;
    &.is-active {
      border-color: var(--light-blue);
    }
  }

  &-ttl {
    font: 600 15px/1.3 var(--title-font-family);
    margin: 0;
    font-size: 14px;
    @include breakpoint(medium) {
      font-size: 18px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    text-decoration: none;
    color: var(--primary-dark-color);
    padding: 16px 0;
    .is-active & {
      color: var(--light-blue);
    }

    svg {
      transition: transform var(--speed-fast);
      fill: var(--primary-dark-color);
      .is-active & {
        fill: var(--light-blue);
        transform: rotate(180deg);
      }
    }
  }

  &-cont {
    display: none;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 1.4;
    font-weight: 300;
    padding-top: 13px;
    border-top: 1px solid var(--light-blue);
    @include breakpoint(small up) {
      font-weight: 600;
    }
  }
}

[data-accordion-content] {
  display: none;

  .is-active & {
    display: block;
  }
}
