@use '../utils/breakpoints' as *;

.footer {
  background: var(--hard-blue);
  color: var(--white);

  &-area {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium up) {
      display: block;
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
    gap: 5px;
    padding-top: 42px;
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 100%;
      background: linear-gradient(
        270deg,
        rgba(12, 12, 18, 0),
        #0094ff 48.96%,
        transparent 100%,
        rgba(12, 12, 18, 0) 0
      );
      @include breakpoint(medium up) {
        bottom: 0;
      }
    }
    @include breakpoint(medium up) {
      padding-top: 0;
      padding-bottom: 36px;
      margin-bottom: 32px;
    }
    @include breakpoint(small up) {
      justify-content: center;
      gap: 46px;
    }
    img {
      max-height: 38px;
      width: auto;
      object-fit: contain;
    }
  }

  &-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    order: -1;
    @include breakpoint(medium up) {
      order: 0;
      justify-content: space-between;
      margin-bottom: 42px;
    }
  }
  &-ttop {
    background: linear-gradient(96.44deg, #5286ce 2.54%, #11338a 97.36%);
    padding: 1px;
    box-shadow: 4px 4px 20px #021033;
    border-radius: 4px;
    cursor: pointer;
    display: none;
    button {
      background: #030f2d;
      border-radius: 4px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #d2d7e1;
        transition: all var(--speed-fast);
      }
      &:hover {
        svg {
          fill: #11a9fe;
        }
      }
    }
    @include breakpoint(medium up) {
      display: block;
    }
  }

  &-area {
    padding-top: 10px;
    padding-bottom: var(--gap);
    gap: var(--gap);

    @include breakpoint(large) {
      padding-top: var(--gap);
    }
  }

  a {
    color: var(--white);

    &:hover {
      color: var(--secondary-light-color);
    }
  }
  &-widget {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    font-weight: 600;
    color: var(--dark-grey);
    font-size: 14px;
    text-align: center;
    @include breakpoint(medium up) {
      flex-direction: row;
    }
  }

  &-menu {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 0;
    max-width: 70%;
    margin: 0 auto 52px auto;
    @include breakpoint(medium up) {
      max-width: 100%;
      flex: 1;
      margin: 0;
      justify-content: center;
    }
    a {
      text-decoration: none;
      color: var(--light-grey);
      font-weight: 400;
      &:hover {
        color: var(--light-blue);
      }
      @include breakpoint(small up) {
        font-weight: 700;
      }
    }

    li:not(:last-child) {
      padding-right: 20px;
      margin-right: 0;
      border: none;
      @include breakpoint(medium up) {
        padding-right: 24px;
        margin-right: 24px;
        border-right: 1px solid #d2d7e1;
      }
    }
  }
}
